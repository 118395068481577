import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Lang } from '@app/i18n/lang.type';
import { Operation } from '../models';

export const operationsLatestActions = createActionGroup({
    source: 'Operations Latest',
    events: {
        'Get Operations': props<{ lang: Lang }>(),
        'Get Operations Success': props<{ items: Operation[] }>(),
        'Get Operations Error': emptyProps(),

        Clear: emptyProps(),
    },
});
