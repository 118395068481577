import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Lang } from '@app/i18n/lang.type';
import { selectOperations, selectLoading } from '../selectors/operations-latest.selector';
import { operationsLatestActions } from '../actions/operations-latest.actions';

@Injectable({
    providedIn: 'root',
})
export class OperationsLatestFacade {
    private store = inject(Store);

    readonly loading$ = this.store.select(selectLoading);
    readonly operations$ = this.store.select(selectOperations);

    getOperationsLatest(lang: Lang): void {
        this.store.dispatch(operationsLatestActions.getOperations({ lang }));
    }

    clear(): void {
        this.store.dispatch(operationsLatestActions.clear());
    }
}
