import { createReducer, on } from '@ngrx/store';
import { operationsLatestActions } from '../actions/operations-latest.actions';
import { Operation } from '../models';

export interface OperationsLatestState {
    loaded: boolean | null;
    operations: Operation[] | null;
}

export const initialState: OperationsLatestState = {
    loaded: null,
    operations: null,
};

export const operationsLatestReducer = createReducer(
    initialState,
    on(
        operationsLatestActions.getOperations,
        (state): OperationsLatestState => ({
            ...state,
            loaded: null,
        }),
    ),
    on(
        operationsLatestActions.getOperationsSuccess,
        (state, payload): OperationsLatestState => ({
            ...state,
            loaded: true,
            operations: payload.items,
        }),
    ),
    on(
        operationsLatestActions.getOperationsError,
        (state): OperationsLatestState => ({
            ...state,
            loaded: false,
        }),
    ),
    on(
        operationsLatestActions.clear,
        (): OperationsLatestState => ({
            ...initialState,
        }),
    ),
);
