import { createReducer, on } from '@ngrx/store';
import { operationsActions } from '../actions/operations.actions';
import { Operation } from '../models';

export interface OperationsState {
    loaded: boolean | null;
    operations: Operation[] | null;
    totalItems: number | null;
}

export const initialState: OperationsState = {
    loaded: null,
    operations: null,
    totalItems: null,
};

export const operationsReducer = createReducer(
    initialState,
    on(
        operationsActions.getOperations,
        (state): OperationsState => ({
            ...state,
            loaded: null,
        }),
    ),
    on(
        operationsActions.getOperationsSuccess,
        (state, payload): OperationsState => ({
            ...state,
            loaded: true,
            operations: payload.items,
            totalItems: payload.totalItems,
        }),
    ),
    on(
        operationsActions.getOperationsError,
        (state): OperationsState => ({
            ...state,
            loaded: false,
            totalItems: 0,
        }),
    ),
    on(
        operationsActions.clear,
        (): OperationsState => ({
            ...initialState,
        }),
    ),
);
