import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ApiListingResponse } from '@app/shared/models';
import { map } from 'rxjs';
import { environment } from '@env';
import { Lang } from '@app/i18n';
import { Operation, OperationDto, OperationsLatestValueDto, OperationsValue, OperationsValueDto } from '../models';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    private httpClient = inject(HttpClient);

    getOperations(params: OperationsValue, lang: Lang) {
        const headers = environment.supportedLanguages.includes(lang) ? { 'Accept-Language': lang } : undefined;

        return this.httpClient
            .addUserSwitch()
            .get<ApiListingResponse<OperationDto[]>>(`api/v1/operations`, {
                params: new OperationsValueDto(params).params,
                headers,
            })
            .pipe(
                map((response) => ({
                    items: response.data.map((item) => new Operation(item)),
                    totalItems: response.pagination.totalItems,
                })),
            );
    }

    getOperationsLatest(lang: Lang) {
        const headers = environment.supportedLanguages.includes(lang) ? { 'Accept-Language': lang } : undefined;

        return this.httpClient
            .addUserSwitch()
            .get<ApiListingResponse<OperationDto[]>>(`api/v1/operations`, {
                params: { ...new OperationsLatestValueDto() },
                headers,
            })
            .pipe(
                map((response) => ({
                    items: response.data.map((item) => new Operation(item)),
                })),
            );
    }
}
