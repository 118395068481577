import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { operationsLatestActions } from '../actions/operations-latest.actions';
import { HttpService } from '../services/http.service';

@Injectable()
export class OperationsLatestEffects {
    private actions$ = inject(Actions);
    private httpService = inject(HttpService);

    getOperations$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(operationsLatestActions.getOperations),
            switchMap(({ lang }) => {
                return this.httpService.getOperationsLatest(lang).pipe(
                    map(({ items }) => operationsLatestActions.getOperationsSuccess({ items })),
                    catchError(() => of(operationsLatestActions.getOperationsError())),
                );
            }),
        );
    });
}
