import { BaseParams, BaseParamsInterface } from '@app/store/models';
import { OperationsValue } from './operations.value';

interface ParamsType extends BaseParamsInterface {
    date_from: string;
    date_to: string;
}

export class OperationsValueDto extends BaseParams {
    private _params: ParamsType;

    constructor(dto: OperationsValue) {
        super(dto);

        this._params = {
            ...this.baseParams,
            date_from: dto.dateFrom,
            date_to: dto.dateTo,
        };
    }

    get params(): Record<string, string> {
        return Object.fromEntries(Object.entries(this._params).filter(([, value]) => value !== undefined && value !== null));
    }
}
