import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { operationsActions } from '../actions/operations.actions';
import { HttpService } from '../services/http.service';

@Injectable()
export class OperationsEffects {
    private actions$ = inject(Actions);
    private httpService = inject(HttpService);

    getOperations$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(operationsActions.getOperations),
            switchMap(({ params, lang }) => {
                return this.httpService.getOperations(params, lang).pipe(
                    map(({ items, totalItems }) => operationsActions.getOperationsSuccess({ items, totalItems })),
                    catchError(() => of(operationsActions.getOperationsError())),
                );
            }),
        );
    });
}
