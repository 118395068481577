import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { OPERATIONS_FEATURE_KEY, OPERATIONS_LATEST_FEATURE_KEY } from './keys';

import { OperationsEffects } from './effects/operations.effects';
import { operationsReducer } from './reducers/operations.reducer';
import { operationsLatestReducer } from './reducers/operations-latest.reducer';
import { OperationsLatestEffects } from './effects/operations-latest.effects';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(OPERATIONS_FEATURE_KEY, operationsReducer),
        StoreModule.forFeature(OPERATIONS_LATEST_FEATURE_KEY, operationsLatestReducer),
        EffectsModule.forFeature([OperationsEffects, OperationsLatestEffects]),
    ],
})
export class OperationsStoreModule {}
