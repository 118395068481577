import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OPERATIONS_LATEST_FEATURE_KEY } from '../keys';
import { OperationsLatestState } from '../reducers/operations-latest.reducer';

const selectState = createFeatureSelector<OperationsLatestState>(OPERATIONS_LATEST_FEATURE_KEY);

const selectLoading = createSelector(selectState, (state: OperationsLatestState) => state.loaded === null);
const selectOperations = createSelector(selectState, (state: OperationsLatestState) => state.operations);

export { selectLoading, selectOperations };
