import { OperationDto } from './operation.dto';

export class Operation {
    id: number;
    date: number;
    cardNumber: string;
    cardType: string;
    plateNumber: string;
    address: string;
    city: string;
    country: string;
    quantity: number;
    amount: number;
    currency: string;
    productName: string;

    constructor(dto: OperationDto) {
        this.id = dto.id;
        this.date = dto.date_ts * 1000;
        this.cardNumber = dto.card_number;
        this.cardType = '??';
        this.plateNumber = dto.plate_number;
        this.address = dto.location_address;
        this.city = dto.location_city;
        this.country = dto.location_country_code;
        this.quantity = dto.quantity;
        this.amount = dto.amount_with_vat;
        this.currency = dto.currency_code;
        this.productName = dto.product_name;
    }
}
