import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OPERATIONS_FEATURE_KEY } from '../keys';
import { OperationsState } from '../reducers/operations.reducer';

const selectState = createFeatureSelector<OperationsState>(OPERATIONS_FEATURE_KEY);

const selectLoading = createSelector(selectState, (state: OperationsState) => state.loaded === null);
const selectOperations = createSelector(selectState, (state: OperationsState) => state.operations);
const selectTotalItems = createSelector(selectState, (state: OperationsState) => state.totalItems);

export { selectLoading, selectOperations, selectTotalItems };
