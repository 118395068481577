import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Lang } from '@app/i18n/lang.type';
import { Operation, OperationsValue } from '../models';

export const operationsActions = createActionGroup({
    source: 'Operations',
    events: {
        'Get Operations': props<{ params: OperationsValue; lang: Lang }>(),
        'Get Operations Success': props<{ items: Operation[]; totalItems: number }>(),
        'Get Operations Error': emptyProps(),

        Clear: emptyProps(),
    },
});
