import { Time } from '@app/shared/classes';

export class OperationsLatestValueDto {
    limit = '5';
    page = '1';
    date_from = '1970-01-01';
    date_to: string;

    constructor() {
        this.date_to = Time.dateToString(Time.today());
    }
}
