import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Lang } from '@app/i18n/lang.type';
import { selectOperations, selectLoading, selectTotalItems } from '../selectors/operations.selector';
import { operationsActions } from '../actions/operations.actions';
import { OperationsValue } from '../models';

@Injectable({
    providedIn: 'root',
})
export class OperationsFacade {
    private store = inject(Store);

    readonly loading$ = this.store.select(selectLoading);
    readonly totalItems$ = this.store.select(selectTotalItems);
    readonly operations$ = this.store.select(selectOperations);

    getOperations(params: OperationsValue, lang: Lang): void {
        this.store.dispatch(operationsActions.getOperations({ params, lang }));
    }

    clear(): void {
        this.store.dispatch(operationsActions.clear());
    }
}
