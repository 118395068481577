import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';
import { OperationsFacade } from '../facades/operations.facade';
import { OperationsLatestFacade } from '../facades/operations-latest.facade';

export function operationsCleanup<T>(): CanDeactivateFn<T> {
    return () => {
        const operationsFacade = inject(OperationsFacade);
        operationsFacade.clear();
        return true;
    };
}

export function operationsLatestsCleanup<T>(): CanDeactivateFn<T> {
    return () => {
        const operationsLatestFacade = inject(OperationsLatestFacade);
        operationsLatestFacade.clear();
        return true;
    };
}
